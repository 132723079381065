<template>
  <div class="homePage">
    <div class="company sc-width">
      <div class="logo-company">
        <div class="left">
          <div class="logo">
            <img v-if="product.company" :src="product.company.avatar.url" />
          </div>
          <div
            class="company-name search-button"
            @click="$router.push('/company/' + product.company.id)"
          >
            {{ product.company.company_name }}
          </div>
        </div>
        <div class="search">
          <div class="input">
            <input
              type="text"
              placeholder="Search products "
              v-model="searchProductValue"
            />
          </div>
          <div
            class="btn search-button"
            @click="searchProduct(product.company.id)"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.677 19.6067L12.962 13.8907C10.4196 15.6981 6.91642 15.256 4.90285 12.8736C2.88929 10.4912 3.03714 6.96324 5.24298 4.75765C7.44824 2.5511 10.9765 2.40261 13.3594 4.41607C15.7422 6.42953 16.1846 9.9331 14.377 12.4757L20.092 18.1917L18.678 19.6057L18.677 19.6067ZM9.48498 4.99964C7.58868 4.99921 5.95267 6.33033 5.56745 8.18709C5.18224 10.0438 6.15369 11.9159 7.89366 12.6699C9.63362 13.4239 11.6639 12.8525 12.7552 11.3017C13.8466 9.75093 13.699 7.64697 12.402 6.26365L13.007 6.86365L12.325 6.18365L12.313 6.17165C11.5648 5.41883 10.5464 4.99678 9.48498 4.99964Z"
                fill="#F2F2F2"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="product-name">{{ product.marketing_name }}</div>
        <div class="tag" :style="{ background: product.tags[0].color }">
          {{ product.tags[0].name }}
        </div>
        <div class="favorite-logo search-button" @click="favorite(product)">
          <template v-if="product.is_favorite">
            <svg
              width="21"
              height="19"
              viewBox="0 0 21 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.7578 3.7172C19.4437 2.98979 18.9907 2.33062 18.4242 1.77658C17.8573 1.22089 17.189 0.779292 16.4555 0.475798C15.6949 0.159844 14.8791 -0.00187919 14.0555 1.64739e-05C12.9 1.64739e-05 11.7727 0.316423 10.793 0.914079C10.5586 1.05705 10.3359 1.21408 10.125 1.38517C9.91406 1.21408 9.69141 1.05705 9.45703 0.914079C8.47734 0.316423 7.35 1.64739e-05 6.19453 1.64739e-05C5.3625 1.64739e-05 4.55625 0.159392 3.79453 0.475798C3.05859 0.780485 2.39531 1.21877 1.82578 1.77658C1.25859 2.32999 0.805495 2.98932 0.492188 3.7172C0.166406 4.47424 0 5.27814 0 6.10548C0 6.88595 0.159375 7.69923 0.475781 8.52658C0.740625 9.21799 1.12031 9.93517 1.60547 10.6594C2.37422 11.8055 3.43125 13.0008 4.74375 14.2125C6.91875 16.2211 9.07266 17.6086 9.16406 17.6649L9.71953 18.0211C9.96563 18.1781 10.282 18.1781 10.5281 18.0211L11.0836 17.6649C11.175 17.6063 13.3266 16.2211 15.5039 14.2125C16.8164 13.0008 17.8734 11.8055 18.6422 10.6594C19.1273 9.93517 19.5094 9.21799 19.7719 8.52658C20.0883 7.69923 20.2477 6.88595 20.2477 6.10548C20.25 5.27814 20.0836 4.47424 19.7578 3.7172Z"
                fill="#F24E1E"
              />
            </svg>
          </template>
          <template v-else>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.83464 2.5C3.5338 2.5 1.66797 4.34667 1.66797 6.625C1.66797 8.46417 2.39714 12.8292 9.57464 17.2417C9.7032 17.3199 9.8508 17.3613 10.0013 17.3613C10.1518 17.3613 10.2994 17.3199 10.428 17.2417C17.6055 12.8292 18.3346 8.46417 18.3346 6.625C18.3346 4.34667 16.4688 2.5 14.168 2.5C11.8671 2.5 10.0013 5 10.0013 5C10.0013 5 8.13547 2.5 5.83464 2.5Z"
                stroke="#BABABA"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </template>
        </div>
      </div>
    </div>
    <div class="tabs sc-width">
      <div class="title">
        <div
          v-for="tab in tabs"
          :key="tab.value"
          @click="activeTab = tab.value"
          :class="[
            'tab',
            'item',
            'search-button',
            { active: activeTab === tab.value }
          ]"
        >
          <div class="name">{{ tab.name }}</div>
          <div class="line"></div>
        </div>
      </div>
      <div class="content">
        <div class="pd">
          <div class="tab-content basicInfo" v-if="activeTab === 'basicInfo'">
            <div class="basic-top sc-width">
              <div class="desc">
                <div class="desc-title">Description</div>
                <div class="desc-content">
                  <div class="pics">
                    <div class=" prev2 search-button" @click="prev2">
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="17.7854"
                          cy="17.7854"
                          r="17.7854"
                          fill="white"
                        />
                        <rect
                          width="22.2318"
                          height="22.2318"
                          transform="translate(7.78113 6.67676)"
                          fill="white"
                        />
                        <path
                          d="M15.6224 25.6523L23.4823 17.7925L15.6224 9.9326L14.3117 11.2424L20.8627 17.7925L14.3117 24.3425L15.6224 25.6523Z"
                          fill="#F57F45"
                        />
                      </svg>
                    </div>
                    <div class="swiper-container swiper2 swiper">
                      <div
                        :class="[
                          'swiper-wrapper',
                          { 'swiper2-init': swiper2Init }
                        ]"
                      >
                        <div
                          :class="[
                            'swiper-slide',
                            'swiper-slide-first',
                            'swiper-slide2',
                            { 'swiper-init': false }
                          ]"
                          v-for="(img, index) in product.images"
                          :key="img.id"
                          @click="swiperChange(index)"
                        >
                          <img alt="" :src="img.url" class="product-img" />
                        </div>
                      </div>
                    </div>
                    <div class=" next2 search-button" @click="next2">
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          r="17.7854"
                          transform="matrix(-1 0 0 1 17.7854 17.7854)"
                          fill="white"
                        />
                        <rect
                          width="22.2318"
                          height="22.2318"
                          transform="matrix(-1 0 0 1 27.7896 6.67676)"
                          fill="white"
                        />
                        <path
                          d="M19.9482 25.6523L12.0884 17.7925L19.9482 9.9326L21.259 11.2424L14.708 17.7925L21.259 24.3425L19.9482 25.6523Z"
                          fill="#F57F45"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="product-other-desc">
                    <div class="descCont">
                      <div class="word">
                        <truncate
                          action-class="customClass"
                          clamp="Read More"
                          less="Show Less"
                          :length="280"
                          type="html"
                          :text="
                            product.property.description || 'No Description'
                          "
                        ></truncate>
                      </div>
                    </div>

                    <div class="btns">
                      <div class="request search-button" @click="requestQuote">
                        <div class="btn quote">
                          <div class="icon">
                            <svg
                              width="24"
                              height="25"
                              viewBox="0 0 24 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18 22.5H6C4.89543 22.5 4 21.6046 4 20.5V4.50001C4 3.39544 4.89543 2.50001 6 2.50001H13C13.2654 2.49907 13.5201 2.60462 13.707 2.79301L19.707 8.79301C19.8954 8.97994 20.0009 9.23462 20 9.50001V20.5C20 21.6046 19.1046 22.5 18 22.5ZM6 4.50001V20.5H16.586L14.02 17.934C13.4101 18.3017 12.7121 18.4973 12 18.5C10.1612 18.5199 8.54049 17.2967 8.05545 15.5229C7.57041 13.7491 8.34318 11.8714 9.93625 10.9529C11.5293 10.0343 13.5415 10.3063 14.8337 11.6147C16.1258 12.9231 16.3724 14.9386 15.434 16.52L18 19.088V9.91401L12.586 4.50001H6ZM12 12.5C10.8954 12.5 10 13.3954 10 14.5C10 15.6046 10.8954 16.5 12 16.5C13.1046 16.5 14 15.6046 14 14.5C14 13.3954 13.1046 12.5 12 12.5Z"
                                fill="#F2F2F2"
                              />
                            </svg>
                          </div>
                          <div class="name">Request a Quote</div>
                        </div>
                      </div>

                      <div class="ask search-button" @click="showQues">
                        <div class="icon">
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14 0.5C15.1046 0.5 16 1.39543 16 2.5L2 2.5L2 13.6765C0.895432 13.6765 0 12.781 0 11.6765V2.5C0 1.39543 0.895431 0.5 2 0.5H14Z"
                              fill="#828282"
                            />
                            <path
                              d="M12 20.5L9.33333 17.6765H6C4.89543 17.6765 4 16.781 4 15.6765V6.5C4 5.39543 4.89543 4.5 6 4.5H18C19.1046 4.5 20 5.39543 20 6.5V15.6765C20 16.781 19.1046 17.6765 18 17.6765H14.6667L12 20.5ZM13.8046 15.6765L18 15.6765V6.5L6 6.5V15.6765H10.1954L12 17.5872L13.8046 15.6765Z"
                              fill="#828282"
                            />
                          </svg>
                        </div>
                        <div class="name">Ask a Question</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="contact padding-common">
                <div class=" card-title">Contact</div>
                <div class="right-main">
                  <div class="logo">
                    <img :src="product.company.first_user.avatar.url" alt="" />
                  </div>
                  <div class="info">
                    <div class="name">
                      {{ upFirstLetter(product.company.first_user.last_name) }}
                    </div>
                    <div class="job">
                      {{ product.company.first_user.job_title }}
                    </div>
                    <div
                      class="contact-message search-button"
                      @click="
                        message(
                          product.company.id,
                          product.company.first_user.id
                        )
                      "
                    >
                      Message
                    </div>
                  </div>
                </div>
                <div class="contact-border"></div>
                <div class="quick">
                  <div class="quick-title">Quick Actions</div>
                  <div class="actions">
                    <div
                      class="item search-button"
                      v-if="!is_exchanged_business_card"
                    >
                      <div class="icon">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20 19.9999H4C2.93052 20.0319 2.03642 19.1933 2 18.1239V5.87494C2.03641 4.80575 2.93068 3.96737 4 3.99994H20C21.0693 3.96737 21.9636 4.80575 22 5.87494V18.1249C21.963 19.1939 21.0691 20.032 20 19.9999ZM4 5.99994V17.9889L20 17.9999V6.01094L4 5.99994ZM13.43 15.9999H6C6.07353 15.172 6.46534 14.4049 7.093 13.8599C7.79183 13.1667 8.73081 12.7691 9.715 12.7499C10.6992 12.7691 11.6382 13.1667 12.337 13.8599C12.9645 14.405 13.3563 15.1721 13.43 15.9999ZM18 14.9999H15V12.9999H18V14.9999ZM9.715 11.9999C9.17907 12.0186 8.65947 11.8138 8.28029 11.4347C7.9011 11.0555 7.69638 10.5359 7.715 9.99994C7.69668 9.4641 7.9015 8.94468 8.28062 8.56556C8.65974 8.18644 9.17916 7.98162 9.715 7.99994C10.2508 7.98162 10.7703 8.18644 11.1494 8.56556C11.5285 8.94468 11.7333 9.4641 11.715 9.99994C11.7336 10.5359 11.5289 11.0555 11.1497 11.4347C10.7705 11.8138 10.2509 12.0186 9.715 11.9999ZM18 10.9999H14V8.99994H18V10.9999Z"
                            fill="#828282"
                          />
                        </svg>
                      </div>
                      <div class="word" @click="exchangeBusinessCard">
                        Exchange Business Card
                      </div>
                    </div>
                    <div class="item search-button">
                      <div class="icon">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14 0C15.1046 0 16 0.895431 16 2L2 2L2 13.1765C0.895432 13.1765 0 12.281 0 11.1765V2C0 0.895428 0.895431 0 2 0H14Z"
                            fill="#828282"
                          />
                          <path
                            d="M12 20L9.33333 17.1765H6C4.89543 17.1765 4 16.281 4 15.1765V6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V15.1765C20 16.281 19.1046 17.1765 18 17.1765H14.6667L12 20ZM13.8046 15.1765L18 15.1765V6L6 6V15.1765H10.1954L12 17.0872L13.8046 15.1765Z"
                            fill="#828282"
                          />
                        </svg>
                      </div>
                      <div class="word search-button" @click="showQues">
                        Ask a Question
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Row :gutter="20" :id="mobileWidth ? 'mobileBoxtab' : ''">
              <i-col :span="mobileWidth ? 24 : 12">
                <div class="k-text">Marketing Name</div>
                <div class="text">{{ product.marketing_name }}</div>
              </i-col>

              <i-col :span="mobileWidth ? 24 : 12">
                <div class="k-text">Descriptive Name</div>
                <div class="text">{{ product.descriptive_name }}</div>
              </i-col>

              <i-col :span="mobileWidth ? 24 : 12">
                <div class="k-text">Other Common Name(s)</div>
                <div class="text">{{ product.other_common_name }}</div>
              </i-col>

              <i-col :span="mobileWidth ? 24 : 12">
                <div class="k-text">Product Type</div>
                <div class="text">{{ product.product_type }}</div>
              </i-col>

              <i-col :span="mobileWidth ? 24 : 12">
                <div class="k-text">Name of Factory</div>
                <div class="text">
                  {{ product.factory && product.factory.factory_name }}
                </div>
              </i-col>

              <i-col :span="mobileWidth ? 24 : 12">
                <div class="k-text">Country of Origin</div>
                <div class="text">
                  {{
                  product.origin_country_info &&
                  product.origin_country_info.name
                  }}
                </div>
              </i-col>

              <i-col :span="mobileWidth ? 24 : 12">
                <div class="k-text">Country of Manufacture</div>
                <div class="text">
                  {{
                  product.manufacture_country_info &&
                  product.manufacture_country_info.name
                  }}
                </div>
              </i-col>

              <i-col :span="mobileWidth ? 24 : 12">
                <div class="k-text">CAS #</div>
                <div class="text">{{ product.cas }}</div>
              </i-col>

              <i-col
                :span="mobileWidth ? 24 : 12"
                v-if="
                  product.industry_usage_grade &&
                    product.industry_usage_grade.length
                "
              >
                <div class="k-text">Industry Usage Grade</div>
                <div class="text">
                  <span
                    v-for="(item, index) in product.industry_usage_grade"
                    :key="item"
                  >
                    <span
                      v-if="index !== product.industry_usage_grade.length - 1"
                    >{{ item }},&nbsp;</span
                    >
                    <span v-else>{{ item }}</span>
                  </span>
                </div>
                <!-- <div class="list">
                  <ul>
                    <li v-for="item in product.industry_usage_grade" :key="item">
                      {{ item }}
                    </li>
                  </ul>
                </div> -->
              </i-col>

              <i-col
                :span="mobileWidth ? 24 : 12"
                v-if="
                  product.compendial_standard &&
                    product.compendial_standard.length
                "
              >
                <div class="k-text">Compendial Standard</div>
                <div class="text">
                  <span
                    v-for="(item, index) in product.compendial_standard"
                    :key="item"
                  >
                    <span
                      v-if="index !== product.compendial_standard.length - 1"
                    >{{ item }},&nbsp;</span
                    >
                    <span v-else>{{ item }}</span>
                  </span>
                  <!-- <ul>
                    <li v-for="item in product.compendial_standard" :key="item">
                      {{ item }}
                    </li>
                  </ul> -->
                </div>
              </i-col>
            </Row>
            <br />

            <template v-if="product.product_type === 'Botanical'">
              <!-- <h2 class="divide">Additional Details for Botanicals</h2> -->
              <!--              <h2 class="divide1" style="margin-top:10px;border:none"></h2>-->

              <Row
                :gutter="20"
                :id="mobileWidth ? 'mobileBoxtab' : ''"
                style="margin-top: -24px;"
              >
                <i-col :span="mobileWidth ? 24 : 12" class="other">
                  <div class="k-text">Botanical Name</div>
                  <div class="text">
                    {{ product.botanicals_additional_details.botanical_name }}
                  </div>
                </i-col>
                <i-col :span="mobileWidth ? 24 : 12" class="other">
                  <div class="k-text">Plant Part Used</div>
                  <div class="text">
                    {{ product.botanicals_additional_details.plant_part_used }}
                  </div>
                </i-col>

                <i-col :span="mobileWidth ? 24 : 12" class="other">
                  <div class="k-text">Botanical Extract Ratio</div>
                  <div class="text">
                    {{
                    product.botanicals_additional_details
                    .botanical_extract_ratio
                    }}
                  </div>
                </i-col>

                <i-col :span="mobileWidth ? 24 : 12" class="other">
                  <div class="k-text">% of Active Component(s)</div>
                  <div class="text">
                    {{
                    product.botanicals_additional_details.of_active_component
                    }}
                  </div>
                </i-col>

                <i-col :span="mobileWidth ? 24 : 12" class="other">
                  <div
                    class="k-text"
                    style="line-height:20px"
                    title="Test Method for Active Component(s)"
                  >
                    Test Method for Active Component(s)
                  </div>
                  <div class="text">
                    {{
                    product.botanicals_additional_details
                    .test_method_of_active_component
                    }}
                  </div>
                </i-col>
              </Row>
            </template>
          </div>

          <div
            class="tab-content documentation"
            v-if="activeTab === 'documentation'"
            :style="
              mobileWidth
                ? 'padding:0 20px'
                : 'padding:15px 68px;margin-bottom:24px'
            "
          >
            <div class="files">
              <Collapse :value="['1', '2', '3']">
                <Panel name="1">
                  Product Documents
                  <div slot="content">
                    <ul class="title">
                      <li span="8">Document Title</li>
                      <li span="8">Document Type</li>
                      <li span="8"></li>
                    </ul>
                    <template v-if="proDocs && proDocs.length">
                      <div :key="item.id" v-for="item in proDocs">
                        <Row :class="curAssDoc === item.name ? 'cur' : ''">
                          <i-col span="8"
                          ><img
                            alt=""
                            class="doc-img"
                            src="@/assets/fileicon.jpg"
                          />
                            <a :href="item.url" class="doc-name">
                              {{ item.name | shortName }}
                            </a>
                          </i-col>
                          <i-col span="8">
                            <div>
                              {{
                              item.extra && item.extra.type
                              ? item.extra.type
                              : "no type"
                              }}
                            </div>
                          </i-col>
                          <i-col span="8">
                            <div
                              @click="downloadFile(item)"
                              class="download-link"
                            >
                              <Icon type="md-download" />
                              {{ mobileWidth ? "" : "Download file" }}
                            </div>
                          </i-col>
                        </Row>
                      </div>
                    </template>
                    <template v-else>
                      <div class="noDatas">No file</div>
                    </template>
                  </div>
                </Panel>
                <Panel name="2">
                  Product Certification
                  <div slot="content">
                    <ul class="title">
                      <li span="8">Document Title</li>
                      <li span="8">Document Type</li>
                      <li span="8"></li>
                    </ul>
                    <template v-if="proCers && proCers.length">
                      <div :key="item.id" v-for="item in proCers">
                        <Row :class="curAssDoc === item.name ? 'cur' : ''">
                          <i-col span="8"
                          ><img
                            class="doc-img"
                            src="@/assets/fileicon.jpg"
                            alt=""
                          />
                            <a :href="item.url" class="doc-name">
                              {{ item.name | shortName }}
                            </a>
                          </i-col>
                          <i-col span="8">{{
                            item.extra && item.extra.type
                            ? item.extra.type
                            : "no type"
                            }}</i-col>
                          <i-col span="8">
                            <a
                              :href="item.url"
                              target="_blank"
                              class="download-link"
                            >
                              <Icon type="md-download" />
                              {{ mobileWidth ? "" : "Download file" }}
                            </a>
                          </i-col>
                        </Row>
                      </div>
                    </template>
                    <template v-else>
                      <div class="noDatas">No file</div>
                    </template>
                  </div>
                </Panel>
                <Panel name="3">
                  Factory Certification
                  <div slot="content">
                    <ul class="title">
                      <li span="8">Document Title</li>
                      <li span="8">Document Type</li>
                      <li span="8"></li>
                    </ul>
                    <template v-if="facCers && facCers.length">
                      <div :key="item.id" v-for="item in facCers">
                        <Row :class="curAssDoc === item.name ? 'cur' : ''">
                          <i-col span="8"
                          ><img
                            class="doc-img"
                            src="@/assets/fileicon.jpg"
                            alt=""
                          />
                            <a :href="item.url" class="doc-name">
                              {{ item.name | shortName }}
                            </a>
                          </i-col>
                          <i-col span="8">{{
                            item.extra && item.extra.type
                            ? item.extra.type
                            : "no type"
                            }}</i-col>
                          <i-col span="8">
                            <a
                              :href="item.url"
                              target="_blank"
                              class="download-link"
                            >
                              <Icon type="md-download" />
                              {{ mobileWidth ? "" : "Download file" }}
                            </a>
                          </i-col>
                        </Row>
                      </div>
                    </template>
                    <template v-else>
                      <div class="noDatas">No file</div>
                    </template>
                  </div>
                </Panel>
              </Collapse>
            </div>
          </div>

          <div
            class="tab-content properties"
            v-if="activeTab === 'properties'"
            style="margin-bottom: 24px;"
          >
            <!-- <Row :gutter="20" v-if="product.property.description">
              <i-col span="24">
                <div class="k-text" style="margin-top:0">Product Description</div>
                <div
                  class="text"
                  style="padding:6px 0"
                  v-html="product.property.description"
                ></div>
              </i-col>
            </Row> -->
            <div style="margin-top:-30px"></div>
            <template
              v-if="
                product.property.appearance &&
                  product.property.appearance.length &&
                  product.property.appearance[0].color
              "
            >
              <div class="k-text">Appearance</div>
              <div class="compose">
                <Row :gutter="20">
                  <i-col span="8">
                    <div class="k-text">COLOR</div>
                    <div class="text">
                      {{ product.property.appearance.color }}
                    </div>
                  </i-col>
                  <i-col span="8">
                    <div class="k-text">Texture</div>
                    <div class="text">
                      {{ product.property.appearance.texture }}
                    </div>
                  </i-col>
                  <i-col span="8">
                    <div class="k-text">State of Matter</div>
                    <div class="text">
                      {{ product.property.appearance.matter_state }}
                    </div>
                  </i-col>
                </Row>
              </div>
            </template>

            <template
              v-if="
                product.property.solubility &&
                  product.property.solubility.length &&
                  product.property.solubility[0].claim
              "
            >
              <div class="k-text">Solubility</div>

              <div class="composeWrap">
                <div
                  class="compose"
                  v-for="(item, index) in product.property.solubility"
                  :key="'Solubility' + index"
                >
                  <Row :gutter="20">
                    <i-col span="6">
                      <div class="k-text" v-if="index === 0">Claim</div>
                      <div class="text">{{ item.claim }}</div>
                    </i-col>
                    <i-col span="6">
                      <div class="k-text" v-if="index === 0">
                        Solvent Medium
                      </div>
                      <div class="text">
                        {{ item.solvent_medium }}
                      </div>
                    </i-col>
                    <i-col span="6">
                      <div
                        class="k-text"
                        v-if="index === 0"
                        title="Amount of Solute (product)"
                      >
                        Amount of Solute (product)
                      </div>
                      <div class="text">
                        {{ item.product_solute_amount }}
                      </div>
                    </i-col>
                    <i-col span="6">
                      <div
                        class="k-text"
                        v-if="index === 0"
                        title="Amount of Solvent (medium)"
                      >
                        Amount of Solvent (medium)
                      </div>
                      <div class="text">
                        {{ item.medium_solute_amount }}
                      </div>
                    </i-col>
                  </Row>
                </div>
              </div>
            </template>

            <template
              v-if="
                product.property.composition_percent &&
                  product.property.composition_percent.length &&
                  product.property.composition_percent[0].ingredient
              "
            >
              <div class="k-text">Percent Composition</div>

              <div class="composeWrap">
                <div
                  class="compose"
                  v-for="(item, index) in product.property.composition_percent"
                  :key="'composition' + index"
                >
                  <Row :gutter="20">
                    <i-col span="12">
                      <div class="k-text" v-if="index === 0">
                        Ingredient Input
                      </div>
                      <div class="text">{{ item.ingredient }}</div>
                    </i-col>
                    <i-col span="12">
                      <div class="k-text" v-if="index === 0">% Composition</div>
                      <div class="text">
                        {{ item.composition }}
                      </div>
                    </i-col>
                  </Row>
                </div>
              </div>
            </template>

            <template
              v-if="
                product.property.particle_size &&
                  product.property.particle_size.length &&
                  product.property.particle_size[0].mesh_screen_size
              "
            >
              <div class="k-text">Particle Size</div>
              <div class="composeWrap">
                <div
                  class="compose"
                  v-for="(item, index) in product.property.particle_size"
                  :key="'size' + index"
                >
                  <Row :gutter="20">
                    <i-col span="8">
                      <div class="k-text" v-if="index === 0">
                        Mesh Screen Size
                      </div>
                      <div class="text">{{ item.mesh_screen_size }}</div>
                    </i-col>
                    <i-col span="8">
                      <div class="k-text" v-if="index === 0">Analysis</div>
                      <div class="text">
                        {{ item.analysis }}
                      </div>
                    </i-col>
                    <i-col span="8">
                      <div class="k-text" v-if="index === 0">% Yield</div>
                      <div class="text">
                        {{ item.yield }}
                      </div>
                    </i-col>
                  </Row>
                </div>
              </div>
            </template>

            <div class="k-text">Storage and Handling</div>
            <div class="compose">
              <Row :gutter="20" v-if="product.property.storage_and_handling">
                <i-col span="8">
                  <div class="k-text">Shelf Life</div>
                  <div class="text">
                    {{
                    product.property.storage_and_handling.shelf_life || "--"
                    }}
                  </div>
                </i-col>
                <i-col span="8">
                  <div class="k-text" title="Recommended Storage Conditions">
                    Recommended Storage Conditions
                  </div>
                  <div class="text">
                    {{
                    product.property.storage_and_handling
                    .recommended_storage_conditions || "--"
                    }}
                  </div>
                </i-col>
                <i-col span="8">
                  <div class="k-text">Packaging Configurations</div>
                  <div class="text">
                    {{
                    product.property.storage_and_handling
                    .packaging_configurations || "--"
                    }}
                  </div>
                </i-col>
              </Row>
            </div>

            <template
              v-if="
                product.property.common_industries_served &&
                  product.property.common_industries_served.length
              "
            >
              <div class="k-text">Common Industries Served</div>
              <div class="list serveLists">
                <ul>
                  <li
                    v-for="item in product.property.common_industries_served"
                    :key="item"
                  >
                    {{ item || "--" }}
                  </li>
                </ul>
              </div>
            </template>
          </div>

          <div
            class="tab-content specifications"
            v-if="activeTab === 'specifications'"
            :style="
              mobileWidth
                ? 'padding:0 20px 20px'
                : 'padding:15px 50px 50px;margin-bottom:24px'
            "
          >
            <div class="k-text" style="margin-top:0">Assay Claims</div>

            <div class="composeWrap">
              <div
                class="compose"
                v-for="(item, index) in product.specification.assay_claims"
                :key="index"
              >
                <Row :gutter="20">
                  <i-col span="8">
                    <div class="k-text" v-if="index === 0">Assay Analyte</div>
                    <div class="text">{{ item.assay_analyte || "--" }}</div>
                  </i-col>
                  <i-col span="8">
                    <div class="k-text" v-if="index === 0">Specification</div>
                    <div class="text">
                      {{ item.specification || "--" }}
                    </div>
                  </i-col>
                  <i-col span="8">
                    <div class="k-text" v-if="index === 0">Test Method</div>
                    <div class="text">
                      {{ item.test_method || "--" }}
                    </div>
                  </i-col>
                </Row>
              </div>
            </div>

            <div class="SpecInfo">
              <Row :gutter="20" :id="mobileWidth ? 'mobileBoxtab' : ''">
                <i-col :span="mobileWidth ? 24 : 12">
                  <div class="k-text">CHEMICAL FORMULA</div>
                  <div class="text">
                    {{ product.specification.chemical_formula || "--" }}
                  </div>
                </i-col>

                <i-col :span="mobileWidth ? 24 : 12">
                  <div class="k-text">MOLECULAR WEIGHT</div>
                  <div class="text">
                    {{ product.specification.molecular_weight || "--" }}
                  </div>
                </i-col>

                <i-col :span="mobileWidth ? 24 : 12">
                  <div class="k-text">PROCESSING METHOD</div>
                  <div class="text">
                    {{ product.specification.processing_method || "--" }}
                  </div>
                </i-col>

                <i-col :span="mobileWidth ? 24 : 12">
                  <div class="k-text">STARTING MATERIAL(S)</div>
                  <div class="text">
                    {{ product.specification.starting_material || "--" }}
                  </div>
                </i-col>

                <i-col :span="mobileWidth ? 24 : 12">
                  <div class="k-text">SOLVENTS USED IN PRODUCTION</div>
                  <div class="text">
                    {{
                    product.specification.solvents_used_in_production || "--"
                    }}
                  </div>
                </i-col>
              </Row>
            </div>
          </div>

          <div
            class="tab-content contaminants"
            v-if="activeTab === 'contaminants'"
            style="margin-bottom: 24px;"
          >
            <div>
              <div class="k-text" style="margin-top:0">
                Breakdown of Microbial Contaminants
              </div>
              <div class="composeWrap">
                <div
                  class="compose"
                  v-for="(item, index) in product.contaminant
                    .microbial_contaminants_breakdown"
                  :key="index"
                >
                  <Row :gutter="20">
                    <i-col span="6">
                      <div class="k-text" v-if="index === 0">Name</div>
                      <div class="text">{{ item.name || "--" }}</div>
                    </i-col>
                    <i-col span="6">
                      <div class="k-text" v-if="index === 0">
                        Microbial Count
                      </div>
                      <div class="text">{{ item.microbial_count || "--" }}</div>
                    </i-col>
                    <i-col span="6">
                      <div class="k-text" v-if="index === 0">Specification</div>
                      <div class="text">
                        {{ item.specification || "--" }}
                      </div>
                    </i-col>
                    <i-col span="6">
                      <div class="k-text" v-if="index === 0">Test Method</div>
                      <div class="text">
                        {{ item.test_method || "--" }}
                      </div>
                    </i-col>
                  </Row>
                </div>
              </div>
            </div>

            <div>
              <div class="k-text">Breakdown of Heavy Metals</div>
              <div class="composeWrap">
                <div
                  class="compose"
                  v-for="(item, index) in product.contaminant
                    .heavy_metals_breakdown"
                  :key="index"
                >
                  <Row :gutter="20">
                    <i-col span="6">
                      <div class="k-text" v-if="index === 0">Name</div>
                      <div class="text">{{ item.name || "--" }}</div>
                    </i-col>
                    <i-col span="6">
                      <div class="k-text" v-if="index === 0">
                        Heavy Metal Count
                      </div>
                      <div class="text">{{ item.heavy_metal_count }}</div>
                    </i-col>
                    <i-col span="6">
                      <div class="k-text" v-if="index === 0">Specification</div>
                      <div class="text">
                        {{ item.specification || "--" }}
                      </div>
                    </i-col>
                    <i-col span="6">
                      <div class="k-text" v-if="index === 0">Test Method</div>
                      <div class="text">
                        {{ item.test_method || "--" }}
                      </div>
                    </i-col>
                  </Row>
                </div>
              </div>
            </div>
          </div>

          <div
            class="tab-content warehouses"
            v-if="activeTab === 'warehouses' && product.warehouses"
            :style="
              product.product_type === 'Botanical'
                ? 'padding:0 50px 50px'
                : 'padding:0 50px 50px;margin-bottom:24px'
            "
          >
            <div
              class="compose wareLists"
              v-for="(item, index) in product.warehouses"
              :key="index"
            >
              <div class="wares">
                <div class="ware">
                  <div class="tit">WAREHOUSE LOCATION</div>
                  <div class="txt">
                    {{ item.address }} {{ item.city }} {{ item.state }}
                    {{ item.country_info && item.country_info.name }}
                  </div>
                </div>
                <div class="ware" style="margin-top: 30px">
                  <div class="tit">INVENTORY COUNT</div>
                  <div class="txt">{{ item.inventory_count }}</div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="tab-content"
            v-if="activeTab === 'analysis'"
            :style="
              mobileWidth
                ? 'padding:0 20px'
                : 'padding:15px 68px;margin-bottom:24px'
            "
          >
            <div class="analysis-wrap">
              <div class="info">
                <div class="price">
                  <p class="name">Price Indicator</p>
                  <p class="num" v-if="product.market_trend === 1">
                    <img
                      src="https://connexima.s3-us-west-1.amazonaws.com/documents/s73uDk49nQqBTUR11tbmnA.jpeg"
                    />Trending up
                  </p>
                  <p class="num" v-if="product.market_trend === -1">
                    <img
                      src="https://connexima.s3-us-west-1.amazonaws.com/documents/fVpWGt4f5J3pu7ZKRiid9F.png"
                    />Trending down
                  </p>
                  <p class="num" v-if="product.market_trend === 0">
                    <img
                      src="https://connexima.s3-us-west-1.amazonaws.com/documents/ejweMYZZXERCcZe12N2EzU.png"
                      alt=""
                    />No Change
                  </p>
                </div>
                <div class="price">
                  <p class="name">Current Price Indication (Market)</p>
                  <p class="num">
                    ${{
                    (product.prices.length && product.prices[0].price) || 0
                    }}
                    / {{ product.prices[0].uom }}
                  </p>
                </div>
              </div>
              <div
                v-if="product.price_notes"
                class="noteWrap"
                style="margin:30px 35px"
              >
                <h4>Notes:</h4>
                <div
                  style="color:#666;font-size:13px;margin-top:5px;width:100%"
                >
                  {{ product.price_notes }}
                </div>
              </div>

              <div id="analysisChart">
                <p
                  v-if="ansLoading"
                  style="color:#ff6600;text-align:center;padding:100px"
                >
                  loading...
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :class="[
        'all-products',
        'sc-width',
        { 'top-class': product.product_type === 'Botanical' }
      ]"
      v-if="product.company.level !== 'Basic'"
    >
      <div class="top">
        <div class="title">Products Categories</div>
        <div
          class="all search button"
          @click="viewAllProduct(product.company.id)"
        >
          All Products
        </div>
      </div>

      <div class="swiper-container swiper3 sc-width">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in companyProduct"
            :key="index"
          >
            <a :href="'/products/' + item.id">
              <div class="hot-item">
                <div class="img">
                  <img alt="" :src="item.images[0].url" />
                </div>
                <div class="name">
                  {{ item.marketing_name.slice(0, 15) }}
                </div>
              </div>
            </a>
          </div>

          <!--              <div class="swiper-slide" v-for="pro in companyProduct" :key="pro.id">-->
          <!--                <a :href="'/product/'+pro.id">-->
          <!--                  <img alt="" :src="pro.images[0].url" class="company-product-img"/>-->
          <!--                </a>-->
          <!--              </div>-->
        </div>
      </div>
      <div class="swiper-button-prev prev3" @click="prev3"></div>
      <div class="swiper-button-next next3" @click="next3"></div>
    </div>
    <div class="product-bottom"></div>
    <Modal
      class="askquestion"
      v-model="showExchange"
      title="Exchange Required"
      footer-hide
      @on-cancel="cancel"
    >
      <div class="content">
        You must exchange business cards in order to respond to an inquiry or
        start a chatroom with another company's member.
      </div>
      <div
        class="btn"
        style="margin-top:15px;text-align: right;color: #ff6600;"
      >
        <Button
          @click="cancel"
          style="width:100px;border: 1px solid #ff6600;color:#ff6600;margin-right:10px;"
        >Cancel</Button
        >
        <Button
          v-if="btnloading"
          loading
          style="width:235px;background: #ff6600;border: 1px solid #ff6600;color:#fff"
        >Submitting...</Button
        >
        <Button
          v-else
          @click="exchangeBusinessCard('ques')"
          style="width:235px;background: #ff6600;border: 1px solid #ff6600;color:#fff"
        >Exchange Business Cards</Button
        >
      </div>
    </Modal>
    <Askquestion ref="askQ" :companyId="product.company.id"></Askquestion>
    <PopLogin ref="popLogin"></PopLogin>
    <Modal
      class="seller-c"
      title="Info"
      @on-cancel="cancel"
      v-model="showSellerInfo"
    >
      <div class="content">
        Seller does not have the permission.
      </div>
    </Modal>
  </div>
</template>

<script>
  import api from "@/api";
  import { mapState } from "vuex";
  import Askquestion from "@/components/askquestion";
  import PopLogin from "@/components/popLogin";
  // import truncate from "vue-truncate-collapsed";
  import { Chart } from "@antv/g2";
  import truncate from "vue-truncate-collapsed";

  // import { MatchMedia } from "vue-component-media-queries";
  const {
    _queryProduct,
    _countDownload,
    _exchangeBusinessCard,
    _queryCompanyProduct,
    _deleteFavorite,
    _createFavorite
  } = api;
  import "swiper/swiper-bundle.min.css";

  import Swiper from "swiper";

  export default {
    components: {
      // MatchMedia,
      truncate,
      Askquestion,
      PopLogin
    },
    filters: {
      shortName(val) {
        if (val.length > 40) return val.substring(0, 40) + "...";
        else return val;
      }
    },
    created() {
      const id = this.$route.params.id;
      this.id = id;
      if (this.$route.query.type) {
        this.activeTab = this.$route.query.type;
      }

      this.loading = true;
      _queryProduct({ id })
        .then(response => {
          const { data } = response;
          this.product = data;
          this.companyId = this.product.company.id;
          this.is_exchanged_business_card = this.product.company.is_exchanged_business_card;
          sessionStorage.setItem(
            "prodcutDocuments",
            JSON.stringify(data.documents)
          );

          if (!this.product.property) {
            this.product.property = {};
          }

          var warehousesAll =
            data.warehouses &&
            ((data.warehouses && data.warehouses.length === 0) ||
              (data.warehouses.length && !data.warehouses[0].city));
          if (!data.warehouses || warehousesAll) this.tabs[5] = "";

          var contaminantsAll =
            data.contaminant &&
            ((data.contaminant.microbial_contaminants_breakdown &&
              data.contaminant.microbial_contaminants_breakdown.length &&
              data.contaminant.microbial_contaminants_breakdown[0].name) ||
              (data.contaminant.heavy_metals_breakdown &&
                data.contaminant.heavy_metals_breakdown.length &&
                data.contaminant.heavy_metals_breakdown[0].test_method));
          if (!contaminantsAll) this.tabs[4] = "";

          var specificationsAll =
            data.specification &&
            ((data.specification.assay_claims.length &&
              data.specification.assay_claims[0].assay_analyte) ||
              data.specification.chemical_formula ||
              data.specification.molecular_weight ||
              data.specification.processing_method ||
              data.specification.starting_material ||
              data.specification.solvents_used_in_production);
          if (!specificationsAll) this.tabs[3] = "";

          var propertiesAll =
            data.property.description ||
            (data.property.appearance &&
              data.property.appearance.length &&
              data.property.appearance[0].color) ||
            (data.property.solubility &&
              data.property.solubility.length &&
              data.property.solubility[0].claim) ||
            (data.property.composition_percent &&
              data.property.composition_percent.length &&
              data.property.composition_percent[0].ingredient) ||
            (data.property.particle_size &&
              data.property.particle_size.length &&
              data.property.particle_size[0].mesh_screen_size) ||
            (data.property.storage_and_handling &&
              data.property.storage_and_handling.shelf_life);
          if (!propertiesAll) this.tabs[2] = "";

          if (!data.documents || (data.documents && data.documents.length === 0))
            this.tabs[1] = "";

          // Analysis
          if (!data.prices || (data.prices && data.prices.length === 0))
            this.tabs[6] = "";

          this.tabs = this.tabs.filter(function(e) {
            return e;
          });

          // if (!this.role) {
          //   this.tabs = [
          //     {
          //       name: "Basic Info",
          //       value: "basicInfo"
          //     }
          //   ];
          // }

          this.proDocs = data.documents?.filter(
            item => item.type === "Product Documents"
          );
          this.proCers = data.documents?.filter(
            item => item.type === "Product Certification"
          );
          this.facCers = data.factory?.document?.filter(
            item => item.type === "Factory Certification"
          );
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
          this.logos = this.product.images;
          this.initSwiper2();
          this.queryCompanyProduct();
          // this.logoUrl = this.product.avatar[this.logoKey].url
        });

      // _queryProductPrice({ id }).then(response => {
      //   const { data } = response;
      //   this.priceLists = data.data;
      //   // this.priceLists.forEach((val, index) => {
      //   //   this.$set(this.priceLists[index], "price", "$" + val.price);
      //   // });
      // });
    },
    computed: {
      ...mapState("user", ["token", "role"])
    },
    data() {
      return {
        mobileWidth: window.innerWidth < 840,
        curAssDoc: sessionStorage.getItem("associatedDoc"),
        loading: false,
        exchangeBusinessCardLoading: false,
        showExchange: false,
        btnloading: false,
        product: {},
        proDocs: [],
        proCers: [],
        facCers: [],
        curItemNum: 0,
        showVideo: false,
        tabs: [
          {
            name: "Basic Info",
            value: "basicInfo"
          },
          {
            name: "Documentation",
            value: "documentation"
          },
          {
            name: "Properties",
            value: "properties"
          },
          {
            name: "Specifications",
            value: "specifications"
          },
          {
            name: "Contaminants",
            value: "contaminants"
          },
          {
            name: "Warehouses",
            value: "warehouses"
          },
          {
            name: "Analysis",
            value: "analysis"
          }
        ],
        activeTab: "basicInfo",
        ansLoading: true,
        priceLists: [],
        swiper: "",
        swiper1: "",
        companyId: "",
        companyProduct: [],
        logoUrl: "",
        logoKey: 0,
        logos: [],
        time: null,
        swiperInit: true,
        swiper2Init: true,
        productCount: 0,
        searchProductValue: "",
        showSellerInfo: false,
        is_exchanged_business_card: false,
        showPopLogin: false
      };
    },
    mounted() {
      this.$nextTick(() => {
        // this.initSwiper()
      });
      // this.timer = setInterval(() => {
      //   if(this.logoKey > this.logos.length){
      //     this.logoKey = 0
      //   }else{
      //     this.logoKey = Number(this.logoKey) + (1)
      //   }
      //   this.logoUrl = this.product.avatar[this.logoKey].url
      // }, 5000)
    },
    methods: {
      favorite(product) {
        if (this.role === "") {
          this.$refs.popLogin.showLogin = true;
        } else {
          if (this.product.is_favorite) {
            this.product.is_favorite = false;
            _deleteFavorite({ id: product.id, type: 0 });
          } else {
            this.product.is_favorite = true;
            _createFavorite({ related_id: product.id, type: 0 });
          }
        }
      },
      upFirstLetter(name) {
        return name.charAt(0).toUpperCase() + name.slice(1);
      },
      requestQuote() {
        if ("" === this.role) {
          this.$refs.popLogin.showLogin = true;
          return;
        }
        if ("exhibitor" === this.role) {
          this.showSellerInfo = true;
          return;
        }
        this.$router.push(
          `/requests/create?id=${
            this.product.id
            }&pro=${this.product.marketing_name.replace(/%/g, "%25")}&fac=${this
            .product.factory && this.product.factory.factory_name}&addr=${(this
            .product.company.company_street_address || "") +
          " " +
          (this.product.company.company_city_address || "") +
          " " +
          (this.product.company.company_state_address || "") +
          " " +
          this.product.company.company_country_address_desc}`
        );
      },
      message(companyId, userId) {
        if ("" === this.role) {
          this.$refs.popLogin.showLogin = true;
          return;
        }
        if ("exhibitor" === this.role) {
          this.showSellerInfo = true;
          return;
        }
        if (this.is_exchanged_business_card) {
          this.$router.push("/chat?company=" + companyId + "&user=" + userId);
        } else {
          this.showExchange = true;
        }
      },
      searchProduct(companyId) {
        this.$router.push({
          path: "/company/" + companyId,
          query: { type: "product", s: this.searchProductValue }
        });
      },
      viewAllProduct(companyId) {
        this.$router.push({
          path: "/company/" + companyId,
          query: { type: "product" }
        });
      },
      viewProduct(item) {
        if (item.redirect_url) {
          window.open(item.redirect_url);
        } else {
          this.$router.push(`/products/` + item.id);
        }
      },
      swiperChange(index) {
        console.log(index);
        this.logoUrl = this.logos[index].url;
        this.swiper2Init = false;
      },
      prev3() {
        this.swiper3.slidePrev();
      },
      next3() {
        this.swiper3.slideNext();
      },
      prev2() {
        this.swiper2.slidePrev();
        // this.swiper2Init = false;
      },
      next2() {
        this.swiper2.slideNext();
        // this.swiper2Init = false;
      },
      navJump(path) {
        if ("home" == path) {
          this.$router.push("/index");
        }
        if ("profile" == path) {
          this.$router.push(`/company/${this.companyId}`);
        }
      },
      initSwiper() {
        this.swiper = new Swiper(".swiper", {
          autoplay: {
            delay: 3000
          },
          loop: true,
          direction: "horizontal",
          slidesPerView: 4,
          spaceBetween: 10,
          autoplayDisableOnInteraction: false,
          navigation: {
            nextEl: ".next1",
            prevEl: ".prev1"
          }
        });
      },
      initSwiper2() {
        this.swiper2 = new Swiper(".swiper2", {
          autoplay: {
            delay: 10000
          },
          loop: true,
          slidesPerView: 1,
          spaceBetween: 0,
          autoplayDisableOnInteraction: false,
          navigation: {
            nextEl: ".next2",
            prevEl: ".prev2"
          }
        });
        // console.log('ppp')
        // this.prev2()
      },
      initSwiper3() {
        this.swiper = new Swiper(".swiper3", {
          autoplay: {
            delay: 3000
          },
          loop: true,
          direction: "horizontal",
          slidesPerView: 6,
          spaceBetween: 10,
          autoplayDisableOnInteraction: false,
          navigation: {
            nextEl: ".next3",
            prevEl: ".prev3"
          }
        });
      },
      prev() {
        this.swiper.slidePrev();
      },
      next() {
        this.swiper.slideNext();
      },
      prev1() {
        this.swiper1.slidePrev();
      },
      next1() {
        this.swiper1.slideNext();
      },
      initSwiper1() {
        this.swiper1 = new Swiper(".swiper1", {
          autoplay: {
            delay: 3000
          },
          loop: true,
          direction: "vertical",
          slidesPerView: 4,
          spaceBetween: 10,
          autoplayDisableOnInteraction: false,
          navigation: {
            nextEl: ".swiper-button-next1",
            prevEl: ".swiper-button-prev1"
          }
        });
      },
      showQues() {
        if ("" === this.role) {
          this.$refs.popLogin.showLogin = true;
          return;
        }
        if ("exhibitor" === this.role) {
          this.showSellerInfo = true;
          return;
        }
        if (this.product.company.is_exchanged_business_card) {
          this.$refs.askQ.showAskques = true;
        } else {
          this.showExchange = true;
        }
      },
      exchangeBusinessCard(type) {
        if ("" === this.role) {
          this.$refs.popLogin.showLogin = true;
          return;
        }
        if (!this.role) {
          this.$router.push("/user-action/login");
          return;
        }
        if ("exhibitor" === this.role) {
          this.showSellerInfo = true;
          return;
        }
        this.btnloading = true;
        this.exchangeBusinessCardLoading = true;
        _exchangeBusinessCard({
          id: this.product.company.id
        })
          .then(() => {
            // console.log(response);
            this.product.company.is_exchanged_business_card = true;
            // this.$Notice.open({
            //   title: `You have exchanged business cards with ${this.company_name}.`
            // });

            if (type === "ques") {
              this.showExchange = false;
              this.showInfo();
            }
          })
          .catch(({ message }) => {
            this.$Message.error(message);
          })
          .finally(() => {
            this.btnloading = false;
            this.showExchange = false;
            this.exchangeBusinessCardLoading = false;
            this.$refs.askQ.showAskques = true;
          });
      },
      drawChart() {
        const chart = new Chart({
          container: "analysisChart",
          autoFit: true,
          height: 300,
          padding: [20, 40, 20, 40]
        });

        // chart.data(this.priceLists);
        // chart.scale({
        //   price_date: {
        //     range: [0, 1]
        //   },
        //   price: {
        //     min: 0,
        //     nice: true
        //   }
        // });

        chart.data(this.product.prices);
        chart.scale({
          price_date: {
            range: [0, 1]
          },
          price: {
            min: 0,
            nice: true
          }
        });

        chart.axis("price", {
          label: {
            formatter: val => {
              return "$" + val; // edit
            }
          }
        });

        chart
          .line()
          .position("price_date*price") // 数据源（一个数组）中每个元素（一个对象）的month属性和temperature属性交会画出来的折线
          .tooltip("price_date*price", function(price_date, price) {
            // tooltip的第一个参数写上需要显示的字段'item1*item2*...'；第二个参数为一个函数，该函数的参数为需要显示的字段。
            return {
              name: "price",
              value: "$" + price
            };
          });

        // chart.line().position("price_date*price");
        // .label("price", xValue => {
        //   return {
        //     content: "$" + xValue
        //   };
        // });
        chart.point().position("price_date*price");

        chart.render();
        // chart.repaint(); //重绘
      },
      downloadFile(item) {
        window.open(item.url);
        _countDownload({
          id: item.id
        });
      },
      showInfo() {
        this.$Notice.info({
          title: "Business card has been exchanged."
        });
      },
      cancel() {
        this.showExchange = false;
      },
      // navJump(type){
      //   if('product' === type){
      //
      //   }
      //   if('profile' === type){
      //     this.$router.push('/test-product-list');
      //   }
      // }
      queryCompanyProduct() {
        _queryCompanyProduct({
          id: this.companyId,
          page: 1,
          limit: 30
        })
          .then(response => {
            const { data } = response;
            this.companyProduct = data.data;
            this.productCount = data.total;
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            // this.initSwiper();
            this.initSwiper3();
          });
      }
    },
    watch: {
      activeTab(nval) {
        if (nval === "analysis") {
          setTimeout(() => {
            this.ansLoading = false;
            this.drawChart();
          }, 500);
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  @media (min-width: 1600px) {
    .basicInfo .basic-top .desc {
      .ask {
        width: 213px !important;
      }
    }
    .swiper3 {
      width: 1414px;
    }
    .prev3 {
      left: 25px;
      top: -180px;
    }
    .next3 {
      left: 1480px;
      top: -215px;
    }
    .product-info .logo .pic img {
      width: 618px;
    }
    .product-info .info .desc {
      width: 658px;
    }
  }
  @media (max-width: 1600px) and (min-width: 1400px) {
    .basic-top {
      .desc {
        width: 850px !important;
        .product-other-desc {
          margin-left: 30px !important;
          .descCont .word {
            width: 440px !important;
          }
        }
      }
      .contact {
        width: 320px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        .quick .actions .item {
          width: 285px !important;
        }
      }
    }
    .swiper3 {
      width: 1170px;
    }
    .prev3 {
      left: 0px;
      top: -180px;
    }
    .next3 {
      left: 1175px;
      top: -215px;
    }
    .product-info .logo .pic img {
      width: 520px;
    }
    .product-info .info .desc {
      width: 475px;
    }
    .right-main .logo {
      width: 90px !important;
      height: 90px !important;
      margin-right: 15px !important;
    }
  }
  @media (max-width: 1400px) and (min-width: 1200px) {
    .basic-top {
      .desc {
        width: 760px !important;
        .request {
          width: 210px !important;
        }
        .swiper2 {
          width: 260px !important;
          height: 243px !important;
        }
        .product-other-desc {
          margin-left: 30px !important;
          .descCont .word {
            width: 390px !important;
          }
        }
        .prev2 {
          left: 223px !important;
          top: 118px !important;
        }
        .next2 {
          top: -160px !important;
        }
      }
      .contact {
        width: 310px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        .quick .actions .item {
          width: 275px !important;
        }
      }
    }
    .prev3 {
      left: 0px;
      top: -180px;
    }
    .next3 {
      left: 1080px;
      top: -215px;
    }
    .product-info .logo .pic img {
      width: 450px;
    }
    .product-info .info .desc {
      width: 425px;
    }
    .right-main .logo {
      width: 80px !important;
      height: 80px !important;
      margin-right: 10px !important;
    }
    .swiper2 img {
      width: 260px !important;
      height: 190px !important;
    }
    .swiper-slide-first {
      width: 260px !important;
    }
  }
  @media (max-width: 1200px) {
    .swiper3 {
      width: 1070px;
    }
    .prev3 {
      left: 0px;
      top: -180px;
    }
    .next3 {
      left: 1100px;
      top: -215px;
    }
  }
  .homePage {
    background: #e5e5e5;
    margin-top: 77px !important;
  }
  .nav {
    display: flex;
    /*width:1500px;*/
    margin: 30px auto;
    padding-top: 30px;
    div {
      height: 12px;
      font-size: 16px;
      font-weight: 400;
      color: #53575c;
      line-height: 17px;
      cursor: pointer;
    }
  }
  .company {
    margin: 0 auto;
    padding-top: 20px;
    .logo-company {
      display: flex;
      justify-content: space-between;
      .left {
        display: flex;
      }
      .logo {
        display: inline-block;
        /*width: 231px;*/
        height: 50px;

        img {
          /*width: 231px;*/
          height: 50px;
        }
      }
      .company-name {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;

        /* Gray 2 */

        color: #4f4f4f;
        display: flex;
        align-items: center;
        margin-left: 10px;
      }
      .search {
        height: 38px;
        border-radius: 8px;
        .input {
          input {
            background: #ffffff;
            border-radius: 8px;
            width: 299px;
            height: 38px;
            font-weight: 500;
            font-size: 14px;
            line-height: 48px;
            /* or 343% */

            display: flex;
            align-items: center;

            /* Gray 4 */

            color: #bdbdbd;
          }
        }
        .btn {
          width: 50px;
          height: 38px;
          background: #f57f45;
          border-radius: 0px 8px 8px 0px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .name {
        display: inline-block;
        .company-name {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;

          /* Gray 2 */

          color: #4f4f4f;
        }
        .product-name {
          height: 23px;
          font-size: 22px;
          font-weight: 700;
          color: #252729;
          position: relative;
          top: -12px;
          left: 47px;
        }
      }
    }
    .product {
      margin-top: 24px;
      display: flex;
      .product-name {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        color: #333333;
      }
      .tag {
        background: #3d3752;
        border-radius: 8px;
        padding-left: 15px;
        padding-right: 15px;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        color: #f2f2f2;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
        height: 32px;
        margin-top: 9px;
      }
      .favorite-logo {
        position: relative;
        top: 14px;
        left: 10px;
      }
    }
  }
  .search {
    display: flex;
    /*margin:10px auto 0 auto;*/
    background: #ffffff;
    /*border: 1px solid #8A9199;*/
    /*border-radius: 5px;*/
    .company-nav {
      height: 47px;
      width: 915px;
      background: #ffffff;
      font-size: 18px;
      font-weight: 500;
      color: #53575c;
      /*line-height: 17px;*/
      padding-top: 10px;
      border: 1px solid #8a9199;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-right: none;
      div {
        display: inline-block;
        cursor: pointer;
      }
      .home {
        margin: 0 65px 0 41px;
      }
      .products {
        margin: 0 65px 0 20px;
      }
      .active {
        color: #ef842d;
      }
      .company-profile {
        margin: 0 65px 0 0px;
      }
      /*border:none;*/
    }
    .input {
      input {
        height: 45px;
        width: 440px;
        border: none;
        /*border-left: 1px solid #8A9199;*/
        padding-left: 10px;
        outline: none;
        font-size: 18px;
      }
    }
    .search-button {
      width: 143px;
      height: 47px;
      background: #ef7f21;
      border-radius: 0px 5px 5px 0px;
      text-align: center;
      padding-top: 8px;
    }
  }
  .border {
    width: 100%;
    height: 20px;
    background: #f0f0f0;
    margin: 40px 0 60px 0;
  }
  .product-info {
    /*width:1500px;*/
    margin: 0 auto;
    display: flex;
    .logo {
      display: flex;
      .logo-swiper {
        width: 112px;
        .swiper2 {
          .swiper-wrapper {
            height: 375px;
            .swiper-slide2 {
              img {
                width: 112px;
                height: 84px;
              }
            }
            .swiper-init {
              margin-left: 60px;
            }
          }
        }
      }
      .pic {
        margin-left: 27px;
        img {
          /*width:618px;*/
          height: 405px;
        }
      }
    }
    .info {
      margin-left: 80px;
      .company-name {
        height: 23px;
        font-size: 22px;
        font-weight: 500;
        color: #ef842d;
        margin-bottom: 15px;
      }
      .product-name {
        /*height: 24px;*/
        font-size: 24px;
        font-weight: 500;
        color: #252729;
        margin-bottom: 20px;
      }
      .tag {
        width: 94px;
        height: 26px;
        background: #3c3853;
        border-radius: 13px;
        margin-bottom: 30px;
        font-size: 14px;

        font-weight: 500;
        color: #ffffff;
        text-align: center;
        padding-top: 3px;
      }
      .desc {
        width: 1080px;
        height: 132px;
        font-size: 16px;
        font-weight: 400;
        color: #252729;
        line-height: 20px;
        overflow-wrap: break-word;
      }
      .operation {
        display: flex;
        position: relative;
        top: 80px;
        .request {
          width: 188px;
          height: 41px;
          background: #ef842d;
          border-radius: 6px;
          margin-right: 28px;
          text-align: center;
          display: table-cell;
          vertical-align: middle;
          font-size: 18px;
          font-weight: 500;
          color: #fefefe;
          padding-top: 2px;
          cursor: pointer;
          .icon {
            position: relative;
            top: 2px;
          }
        }
        .ask {
          width: 200px;
          height: 41px;
          background: #ffffff;
          border: 1px solid #ef842d;
          border-radius: 6px;
          text-align: center;
          display: table-cell;
          vertical-align: middle;
          font-size: 18px;
          font-weight: 500;
          color: #ef7f21;
          padding-top: 7px;
          cursor: pointer;
          margin-left: 10px;
          .icon {
            position: relative;
            top: 3px;
          }
        }
      }
    }
  }
  .products {
  }
  .tabs {
    margin: 27px auto 0 auto;
    .title {
      display: flex;
      /*width:1500px;*/
      margin: 0 auto;
      overflow: hidden;
      border-radius: 10px;

      .item {
        height: 59px;
        background: #fff;
        flex: 1;

        .name {
          font-weight: 600;
          font-size: 16px;
          line-height: 48px;
          text-align: center;
          padding-top: 7px;
        }
      }
      .active {
        color: #f57f45;
        .name {
          color: #f57f45;
          font-weight: bold;
          font-size: 16px;
          line-height: 48px;
        }
        .line {
          height: 0px;
          border: 2px solid #f57f45;
          width: 140px;
          margin: 0 auto;
        }
      }
    }
  }
  .pd {
    /*min-height: 500px;*/
    padding-top: 25px;
    .tab-content {
      /*padding-bottom: 10px;*/
      /*padding-top: 20px !important;*/
      border-radius: 10px;
      background: #fff;
      .analysis-wrap {
        position: relative;
        padding-bottom: 50px;
        .info {
          display: flex;
          width: 410px;
          padding: 15px;
          border: 1px solid #dedede;
          border-radius: 6px;
          margin: 0 0 40px 0;
          .price {
            &:first-child {
              padding-right: 20px;
            }
            &:last-child {
              flex: 0 0 63%;
              padding-left: 20px;
              border-left: 1px solid #dedede;
            }
            .name {
              color: #999;
            }
            .num {
              color: #333;
              font-weight: bold;
              padding-top: 8px;
              img {
                width: 35px;
                vertical-align: top;
              }
            }
          }
        }
      }

      .ivu-collapse {
        border: none;
        background-color: #fff;
      }
      /deep/ .ivu-collapse-item {
        position: relative;
        margin-bottom: 30px;
        border: none;
      }
      .title {
        position: absolute;
        top: 50px;
        left: 0;
        width: 100%;
        padding: 6px 20px;
        background-color: #f2f2f2;
        font-weight: bold;
        color: #999;
        font-size: 12px;
        list-style: none;
        display: flex;
        font-size: 17px;
        li {
          flex: 0 0 33.3%;
          &:first-child {
            flex: 0 0 46%;
          }
        }
      }
      .noDatas {
        text-align: center;
        color: #999;
        padding-top: 30px;
      }
      /deep/ .ivu-collapse-header {
        font-size: 16px;
        position: relative;
        padding-left: 2px;
        margin-bottom: 10px;
        color: #000;
        background-color: #fff;
        font-weight: bold;
        border: none;
        .ivu-icon-ios-arrow-forward {
          position: absolute;
          top: 12px;
          left: 170px;
          right: auto;
          color: @primary-color;
        }
      }
      /deep/ .ivu-collapse-content {
        font-size: 13px;
        padding-top: 30px;
        padding-left: 0;
        padding-right: 0;
        .ivu-row {
          padding: 6px 16px;
          font-size: 17px;
          &.cur {
            background-color: #fef2e5;
          }
          & .ivu-col:first-child {
            width: 46%;
          }
          & .ivu-col:last-child {
            width: 20%;
          }
        }
      }
    }
    .doc-img {
      width: 14px;
      vertical-align: text-top;
      margin-right: 2px;
    }
    .doc-name {
      color: @primary-color;
      text-decoration: underline;
    }
    .download-link {
      width: 100%;
      display: inline-block;
      color: @primary-color;
      cursor: pointer;
      text-align: right;
    }
    .divide {
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .basicInfo {
      background: #e5e5e5;
      .ivu-row {
        background: #ffffff;
        border-radius: 10px;
        margin: 24px 0 0 0;
        padding-left: 30px;
        padding-right: 30px;
      }
      .ivu-col {
        display: flex;
        padding: 10px 0 !important;
        border-bottom: 1px solid #ccc;
        width: 47%;
        &:nth-child(2n-1) {
          margin-right: 6%;
        }
      }
      .k-text {
        flex: 0 0 250px;
        line-height: 34px;
        color: #000;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .text {
        width: 100%;
        overflow: hidden;
        padding: 6px 10px;
        min-height: 34px;
        font-size: 17px;
      }
      .basic-top {
        display: flex;
        justify-content: space-between;
        .desc {
          background: #fff;
          border-radius: 10px;
          width: 1085px;
          padding: 23px 40px 0 40px;
          .desc-content {
            display: flex;
          }
          .desc-title {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: #000000;
            margin-bottom: 7px;
          }
          .btns {
            display: flex;
            margin-top: 67px;
            .request {
              width: 222px;
              height: 47px;
              background: #f57f45;
              border-radius: 8px;
            }
            .quote {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 9px 20px;

              position: absolute;
              /* Primary */
              .name {
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                /* identical to box height */

                display: flex;
                align-items: center;
                text-align: center;
                /* Gray 6 */
                color: #f2f2f2;
                margin-left: 20px;
              }
              .icon {
                position: relative;
                top: 2px;
              }
            }
            .ask {
              display: flex;
              width: 188px;
              height: 47px;
              background: #ffffff;
              border: 1px solid #ef842d;
              border-radius: 6px;
              text-align: center;
              justify-content: center;
              align-items: center;
              margin-left: 20px;
              .icon {
                position: relative;
                top: 3px;
              }
              .name {
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                /* identical to box height */

                display: flex;
                align-items: center;
                text-align: center;

                /* Black */

                color: #222222;
                margin-left: 20px;
              }
            }
          }
        }
        .contact {
          width: 388px;
          height: 425px;
          left: 967px;
          top: 262px;
          background: #ffffff;
          border-radius: 10px;
          padding-bottom: 20px;
          padding-left: 35px;
          padding-right: 35px;
          .card-title {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            /* identical to box height, or 140% */

            display: flex;
            align-items: center;

            color: #000000;
            margin-top: 23px;
          }
          .right-main {
            display: flex;
            margin-bottom: 24px;
            margin-top: 28px;
            .logo {
              width: 100px;
              height: 100px;
              justify-content: center;
              display: flex;
              margin-right: 28px;
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
            .info {
              margin-top: 5px;
            }
            .name {
              font-weight: bold;
              font-size: 18px;
              line-height: 30px;
              /* identical to box height, or 167% */

              display: flex;
              align-items: center;
              /*justify-content: center;*/
              /* Black */

              color: #222222;
            }
            .job {
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              /* identical to box height, or 150% */

              display: flex;
              align-items: center;
              /*justify-content: center;*/
              color: #444444;
            }
            .contact-message {
              width: 122px;
              height: 38px;
              background: #f57f45;
              border-radius: 8px;
              align-items: center;
              text-align: center;

              /* White */
              justify-content: center;
              color: #ffffff;
              display: flex;
              margin: 30px auto 0 auto;
            }
          }
          .contact-border {
            height: 0px;
            border: 1px solid #e0e0e0;
          }
          .quick {
            .quick-title {
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              display: flex;
              align-items: center;
              color: #4f4f4f;
              margin: 25px 0 20px 0;
            }
            .actions {
              .item {
                display: flex;
                border: 1px solid #f57f45;
                box-sizing: border-box;
                border-radius: 8px;
                width: 301px;
                height: 42px;
                margin-bottom: 12px;
                .icon {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-left: 18px;
                  margin-right: 18px;
                }

                .word {
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 20px;

                  display: flex;
                  align-items: center;
                  text-align: center;
                  color: #222222;
                }
              }
            }
          }
        }
      }
    }

    .SpecInfo {
      .ivu-row {
        margin: 0 10px !important;
        .ivu-col {
          display: flex;
          padding: 10px 0 !important;
          border-bottom: 1px solid #ccc;
          width: 47%;
          &:nth-child(2n-1) {
            margin-right: 6%;
          }
        }
        .k-text {
          flex: 0 0 250px;
          line-height: 34px;
          color: #000;
          font-weight: bold;
          font-size: 14px !important;
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }
        .text {
          width: 100%;
          overflow: hidden;
          padding: 6px 10px;
          min-height: 34px;
        }
      }
    }

    .properties,
    .specifications,
    .contaminants {
      padding: 15px 68px 50px;
      background: #fff;
      .k-text {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #000;
        margin-top: 30px;
      }
      .text {
        width: 100%;
        overflow: hidden;
        padding: 6px 10px;
        min-height: 34px;
        font-size: 17px;
      }
    }

    .warehouses {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      padding: 0px 50px 50px;
      .wareLists {
        flex: 0 0 325px;
        border: 1px solid #f2f2f2;
        border-radius: 4px;
        box-shadow: 0 0 2px #f8f8f8;
        margin: 20px;
        padding: 15px;
        .wares {
          .tit {
            font-weight: bold;
            font-size: 16px;
            color: #999;
          }
          .txt {
            color: #000;
            margin-top: 6px;
            font-size: 16px;
          }
        }
      }
    }

    .form-box-title {
      text-align: right;
      vertical-align: middle;
      float: left;
      font-size: 12px;
      color: #515a6e;
      line-height: 1;
      padding: 10px 12px 10px 0;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .compose {
      width: 100%;
      padding: 0 10px;
      border-radius: 4px;
      .k-text {
        margin-top: 0px;
        font-size: 14px;
        color: #999;
        background-color: #f2f2f2;
        font-size: 17px;
      }
      .ivu-col {
        padding: 0 !important;
        .k-text {
          padding: 4px 10px;
          font-size: 17px;
        }
      }
    }
    .list {
      width: 100%;
      overflow: hidden;
    }
    .serveLists {
      ul {
        display: flex;
        list-style: none;
        margin-top: 10px;
        li {
          padding: 4px 10px;
          border-radius: 6px;
          margin-right: 10px;
          background-color: #fbc6a1;
        }
      }
    }
  }

  .swiper-slide-first {
    width: 304px;
    /*height: 293px;*/
    .company-product-img {
      width: 102px;
      height: 82px;
    }
  }
  .swiper-slide-second {
    width: 112px !important;
    height: 94px !important;
    img {
      width: 112px;
      height: 84px;
    }
  }
  .swiper-button-prev1 {
    position: relative;
    left: 6px;
    top: 90px;
    font-size: 20px;
    --swiper-navigation-size: 20px;
    --swiper-navigation-color: #ff6600;
    left: -30px;
    top: -45px;
    color: #b2b2b2;
  }
  .swiper-button-next1 {
    position: relative;
    right: 3px;
    top: 90px;
    font-size: 20px;
    --swiper-navigation-size: 20px;
    --swiper-navigation-color: #ff6600;
    left: 508px;
    top: -55px;
    color: #b2b2b2;
  }
  .company {
    .products {
      .total {
        text-align: right;
        margin-right: 30px;
      }
      width: 400px;
      .swiper1 {
        margin-right: 30px;
      }
    }
  }
  .prev1 {
    position: relative;
    left: 6px;
    top: 90px;
    font-size: 20px;
    --swiper-navigation-size: 20px;
    --swiper-navigation-color: #ff6600;
    left: -30px;
    top: 52px;
    color: #b2b2b2;
  }
  .next1 {
    position: relative;
    right: 3px;
    top: 90px;
    font-size: 20px;
    --swiper-navigation-size: 20px;
    --swiper-navigation-color: #ff6600;
    left: 382px;
    top: -40px;
    color: #b2b2b2;
  }
  .top-class {
    margin-top: 24px !important;
  }
  .all-products {
    margin: 0px auto 0 auto;
    background: #ffffff;
    border-radius: 10px;
    height: 380px;
    .top {
      display: flex;
      justify-content: space-between;
      .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        /* identical to box height, or 140% */
        color: #000000;
        margin: 27px 0 0 36px;
      }
      .all {
        width: 183px;
        height: 38px;
        left: 1166px;
        top: 1240px;

        /* Primary */

        border: 1px solid #f57f45;
        box-sizing: border-box;
        border-radius: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height */

        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        /* Black */

        color: #222222;
        margin: 20px 36px 0 0;
      }
    }

    .divided {
      height: 1px;
      background: #999999;
      margin-top: 15px;
      margin-bottom: 25px;
    }
    .all {
      font-size: 22px;

      font-weight: 500;
      color: #ef842d;
      cursor: pointer;
      /*margin-bottom:36px;*/
    }
  }
  .part {
    height: 10px;
    background: #f0f0f0;
    width: 100%;
    margin-top: 45px;
    margin-bottom: 45px;
  }
  .swiper3 {
    .swiper-slide {
      /*width:320px !important;*/
      /*height:278px;*/
      .company-product-img {
        width: 320px;
        height: 188px;
      }
    }
  }
  .hot-item {
    width: 278px;
    height: 320px;
    padding-top: 22px;
    background: #ffffff;
    .img {
      width: 240px;
      height: 188px;
      padding-right: 10px;
      padding-left: 10px;
      img {
        width: 240px;
        height: 188px;
        border-radius: 6px;
        padding: 33px 19px 0 19px;
      }
    }
    .name {
      padding-top: 30px;
      padding-right: 40px;
      font-size: 16px;

      font-weight: 400;
      color: #53575c;
      float: left;
      padding-left: 35px;
    }
  }
  .prev2 {
    position: relative;
    width: 20px;
    height: 38px;
    top: 146px;
    left: 265px;
    z-index: 10;
  }
  .next2 {
    position: relative;
    width: 20px;
    height: 38px;
    top: -124px;
    left: 2px;
    z-index: 10;
  }
  .prev3 {
    position: relative;
    font-size: 20px;
    --swiper-navigation-size: 20px;
    --swiper-navigation-color: #ff6600;
    color: #252729;
    width: 20px;
    height: 38px;
  }
  .next3 {
    position: relative;
    font-size: 20px;
    --swiper-navigation-size: 20px;
    --swiper-navigation-color: #ff6600;
    color: #252729;
    width: 20px;
    height: 38px;
  }
  .logo-swiper {
    .swiper2-init {
      transform: translate3d(0px, 0px, 0px) !important;
    }
  }
  .product-bottom {
    height: 30px;
  }
  .swiper2 {
    width: 304px;
    height: 233px;
    img {
      width: 304px;
      height: 233px;
    }
  }
  .k-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
<style lang="less">
  .product-other-desc {
    margin-left: 47px;
    .descCont {
      overflow-y: auto;
      height: 200px;
      .word {
        padding-right: 0;
        padding-top: 10px;
        margin-top: 10px;
        height: 175px;
        /*overflow-y: scroll;*/
        width: 640px;
        overflow-wrap: break-word;
        .customClass {
          display: block;
          margin: 10px auto;
          border: none;
          outline: none;
          background: none;
          color: @primary-color;
          cursor: pointer;
        }
      }
    }
  }

  .ivu-row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
</style>
