<template>
  <div>
    <Modal
      class="askquestion"
      v-model="showAskques"
      title="Ask a question"
      footer-hide
      @on-cancel="cancel"
    >
      <h4>SPECIFIC PRODUCT(S)</h4>

      <div class="search-box">
        <div class="input-box">
          <Icon type="ios-search" class="search-icon" />
          <input
            v-model="filter.search"
            v-on:keyup.enter="handleSearch"
            type="text"
            placeholder="Search for a product or representative..."
          />
        </div>
        <div class="btn" @click="handleSearch">Search</div>
        <div class="btn clearbtn" shape="circle" @click="clearSearch">
          Clear
        </div>
      </div>
      <div class="content">
        <Table
          border
          ref="selection"
          :columns="colPros"
          :data="lists"
          :loading="loading"
          height="190"
          @on-selection-change="seledItems"
        >
          <template slot-scope="{ row: item }" slot="saleUsers">
            <span v-if="item.sale_users.length">
              {{ item.sale_users[0].first_name }}
              {{ item.sale_users[0].last_name }}
            </span>
            <span v-else>--</span>
          </template>
        </Table>
        <h4>COMMENT</h4>
        <Input
          v-model="askForm.comment"
          type="textarea"
          placeholder="Enter a comment here..."
          :rows="4"
          style="width: 100%"
        />
      </div>
      <div
        class="btn"
        style="margin-top:15px;text-align: right;color: #ff6600;"
      >
        <Button
          v-if="loading"
          loading
          style="width:140px;border: 1px solid #ff6600;color:#ff6600"
          >Submitting...</Button
        >
        <Button
          v-else
          style="width:100px;border: 1px solid #ff6600;color:#ff6600"
          @click="sendAsk"
          >Submit</Button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import api from "@/api";
const { _queryCompanyProduct, _createQuestion } = api;

export default {
  props: ["companyId", "productId"],
  data() {
    return {
      loading: false,
      showAskques: false,
      filter: {},
      askForm: {
        company_id: this.companyId || this.$route.params.id,
        comment: "",
        product: []
      },
      colPros: [
        {
          type: "selection",
          width: 60,
          align: "center"
        },
        {
          title: "PRODUCT NAME",
          key: "marketing_name"
        },
        {
          title: "SALES REPRESENTATIVE",
          slot: "saleUsers"
        }
      ],
      lists: []
    };
  },
  mounted() {
    this.queryAllProductList();
  },
  methods: {
    clearSearch() {
      this.page = 1;
      this.filter.search = "";
      this.queryAllProductList();
    },

    handleSearch() {
      this.page = 1;
      this.queryAllProductList();
    },
    queryAllProductList() {
      this.loading = true;

      _queryCompanyProduct({
        id: this.askForm.company_id,
        page: this.page,
        limit: this.limit,
        ...this.filter
      })
        .then(response => {
          const { data } = response;
          this.lists = data.data;
          if (this.productId) {
            this.seledItems([{ id: this.productId }]);
            this.lists.forEach((item, index) => {
              if (this.productId === item.id)
                this.$set(this.lists[index], "_checked", true);
            });
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    seledItems(sels) {
      this.askForm.product = sels.map(item => item.id);
    },
    sendAsk() {
      this.loading = true;
      _createQuestion(this.askForm)
        .then(() => {
          this.$Message.success("Add question success");
          this.showAskques = false;
          this.askForm.comment = "";
          this.askForm.product = [];
          this.queryAllProductList();
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.loading = false;
      this.askForm.comment = "";
      this.askForm.product = [];
      this.queryAllProductList();
    }
  }
};
</script>
<style lang="less">
.askquestion {
  h4 {
    font-size: 12px;
    padding-bottom: 10px;
  }
  .content {
    margin-top: 10px;
  }
  .ivu-table-wrapper {
    margin-bottom: 15px;
  }
  .ivu-table {
    font-size: 12px;
  }

  .ivu-table th,
  .ivu-table td {
    height: 30px;
    border: none;
    border-bottom: 1px solid #f2f2f2;
  }
}

.search-box {
  display: flex;
  align-items: center;
  height: 35px;
  // width: 600px;
  .input-box {
    position: relative;
    flex: 1;
    // width: 600px;
    height: 100%;
    background: #fff;
    line-height: initial;
    padding-left: 40px;
    padding-right: 20px;
    border: 1px solid #dfdfdf;
    border-right: none;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    .search-icon {
      position: absolute;
      top: 7px;
      left: 10px;
      font-size: 20px;
      color: #888;
    }
    input {
      width: 100%;
      height: 100%;
      outline: none;
      background: transparent;
      border: none;
      color: #444;
      caret-color: #888;

      &::-webkit-input-placeholder {
        color: #bdcada;
      }
      &:-moz-placeholder {
        color: #bdcada;
      }
      &::-moz-placeholder {
        color: #bdcada;
      }
      &:-ms-input-placeholder {
        color: #bdcada;
      }
    }
  }
  .tit {
    padding: 0 10px;
    margin-left: 10px;
    &.first {
      padding-left: 0;
      margin-left: 0;
    }
  }
  .ivu-btn-circle {
    color: @primary-color;
    border: 1px solid @primary-color;
    margin-left: 20px;
  }
  .btn {
    cursor: pointer;
    background: #fff;
    color: @primary-color;
    border: 1px solid @primary-color;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-self: center;
    line-height: initial;
    height: 100%;
  }
  .clearbtn {
    margin-left: 10px;
    color: #666;
    border: 1px solid #ddd;
    border-radius: 20px;
  }
  .clearbtn2 {
    margin-left: 190px;
    color: #fff;
    background-color: @primary-color;
    border: 1px solid @primary-color;
    border-radius: 6px;
  }
  .type-wrap {
    position: absolute;
    right: 20px;
    color: #ffca94;
    font-size: 20px;
    cursor: pointer;
    /deep/ .ivu-icon-ios-list {
      position: relative;
      top: 3px;
      font-size: 32px;
      font-weight: bold;
    }
    .cur {
      color: @primary-color;
    }
  }
}
</style>
